// Official Technica Colors
$cyan: darken(#00ffff, 2%); // Slight darken hack for readability
$pink: #ff7bac;
$lavender: #b6a1c4;
$grey: #464343;
$darker-grey: #2d2d2d;
$black: #000000;
$white: #ffffff;

// Custom
$bg-black: #222222;
$bg-black-2: #1a1a1a;
